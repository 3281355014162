// extracted by mini-css-extract-plugin
export var bodyGrid = "CostingChart-module--body-grid--db0d5";
export var centerAlignedBody = "CostingChart-module--centerAlignedBody--edd2f";
export var chartContainer = "CostingChart-module--chartContainer--3d112";
export var dataBar = "CostingChart-module--dataBar--5d8eb";
export var dataBarContainer = "CostingChart-module--dataBarContainer--88b34";
export var dataLabel = "CostingChart-module--dataLabel--7469f";
export var sectionGrid = "CostingChart-module--sectionGrid--ee1ab";
export var sectionGridAlignCenter = "CostingChart-module--sectionGridAlignCenter--50e91";
export var titleGroup = "CostingChart-module--titleGroup--79e94";
export var xAxisLabel = "CostingChart-module--xAxisLabel--1543d";
export var yAxisGridLine = "CostingChart-module--yAxisGridLine--70972";
export var yAxisLabel = "CostingChart-module--yAxisLabel--eed04";
export var yAxisUnitLabel = "CostingChart-module--yAxisUnitLabel--19ea2";
export var yAxisZeroLabel = "CostingChart-module--yAxisZeroLabel--d9eb1";